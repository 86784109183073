import Vue from 'vue'
import VueRouter from 'vue-router'
import Api from '@/core/Api';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Login.vue')
  }, 
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/layout/Main"),
    children: [
      {
        path: "/reglement",
        name: "reglement",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "reglement-liste",
            component: () => import("@/views/app/reglement/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },   
          {
            path: "detail",
            name: "reglement-detail",
            component: () => import("@/views/app/reglement/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },                     
          {
            path: "solde",
            name: "reglement-solde",
            component: () => import("@/views/app/reglement/Solde.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },            
          {
            path: "soldes",
            name: "reglement-soldes",
            component: () => import("@/views/app/reglement/Soldes.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },            
        ]
      },
      {
        path: "/camion",
        name: "camion",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "camion-liste",
            component: () => import("@/views/app/camion/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },                                   
          {
            path: "lot/liste",
            name: "camion-lot",
            component: () => import("@/views/app/camion/LotListe.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },                                   
          {
            path: "lot/carte",
            name: "camion-carte",
            component: () => import("@/views/app/camion/LotCarte.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },                                   
        ]
      },        
      {
        path: "/route",
        name: "route",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "detail/:id",
            name: "route-detail",
            component: () => import("@/views/app/route/Detail.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "create",
            name: "route-create",
            component: () => import("@/views/app/route/Create.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "liste",
            name: "route-liste",
            component: () => import("@/views/app/route/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "edit/:id",
            name: "route-edit",
            component: () => import("@/views/app/route/Create.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
        ]
      },
      {
        path: "/facturation",
        name: "facturation",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "auto",
            name: "facturation-auto",
            component: () => import("@/views/app/facturation/Auto.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "mb",
            name: "facturation-mb",
            component: () => import("@/views/app/facturation/MB.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
        ]
      },
      {
        path: "/demenagement",
        name: "demenagement",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "disponible",
            name: "demenagement-disponible",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "demenagement-disponible-liste",
                component: () => import("@/views/app/demenagement/disponible/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "calendrier",
                name: "demenagement-disponible-calendar",
                component: () => import("@/views/app/demenagement/disponible/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "carte",
                name: "demenagement-disponible-map",
                component: () => import("@/views/app/demenagement/disponible/Map.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "test",
                name: "demenagement-disponible-test",
                component: () => import("@/views/app/demenagement/disponible/Test.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },                
            ]
          },
          {
            path: "postulee",
            name: "demenagement-postulee",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "demenagement-postulee-liste",
                component: () => import("@/views/app/demenagement/postulee/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "calendrier",
                name: "demenagement-postulee-calendar",
                component: () => import("@/views/app/demenagement/postulee/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              }
            ]
          },
          {
            path: "tous",
            name: "demenagement-tous",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "demenagement-tous-liste",
                component: () => import("@/views/app/demenagement/tous/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "calendrier",
                name: "demenagement-tous-calendar",
                component: () => import("@/views/app/demenagement/tous/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "carte",
                name: "demenagement-tous-map",
                component: () => import("@/views/app/demenagement/tous/Map.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
            ]
          },
          {
            path: "vendu",
            name: "demenagement-vendu",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "demenagement-vendu-liste",
                component: () => import("@/views/app/demenagement/vendu/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "calendrier",
                name: "demenagement-vendu-calendar",
                component: () => import("@/views/app/demenagement/vendu/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
              {
                path: "carte",
                name: "demenagement-vendu-map",
                component: () => import("@/views/app/demenagement/vendu/Map.vue"),
                meta: {
                  rule: 'isAdmin',
              logged: true
                }
              },
            ]
          },
          {
            path: "mouvement",
            name: "demenagement-mouvement",
            component: () => import("@/views/app/demenagement/Mouvement.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "facturation",
            name: "demenagement-facturation",
            component: () => import("@/views/app/demenagement/Facturation.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "map", 
            name: "demenagement-map",
            component: () => import("@/views/app/demenagement/Map.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "demenagement-detail",
            component: () => import("@/views/app/demenagement/Detail.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "logs",
            name: "demenagement-logs",
            component: () => import("@/views/app/demenagement/Logs.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "lv",
            name: "demenagement-lv",
            component: () => import("@/views/app/demenagement/LV.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "feuille",
            name: "demenagement-feuille",
            component: () => import("@/views/app/demenagement/Feuille.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "suivi",
            name: "demenagement-suivi",
            component: () => import("@/views/app/demenagement/Suivi.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "solde",
            name: "demenagement-solde",
            component: () => import("@/views/app/demenagement/Solde.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "horaire",
            name: "demenagement-horaire",
            component: () => import("@/views/app/demenagement/Horaire.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "decalage",
            name: "demenagement-decalage",
            component: () => import("@/views/app/demenagement/Decalage.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "synthese",
            name: "demenagement-synthese",
            component: () => import("@/views/app/demenagement/Synthese.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "monte-meuble",
            name: "demenagement-monte-meuble",
            component: () => import("@/views/app/demenagement/MonteMeuble.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "dreal",
            name: "demenagement-dreal",
            component: () => import("@/views/app/demenagement/Dreal.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "devis-attente",
            name: "demenagement-devis-attente",
            component: () => import("@/views/app/demenagement/DevisAttente.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "ratio",
            name: "demenagement-ratio",
            component: () => import("@/views/app/demenagement/Ratio.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          }
        ]
      },
      {
        path: "/mails",
        name: "Mail",
        component: () => import("@/views/app/Mail.vue"),
        meta: {
          rule: 'isAdmin',
          logged: true
        }
      },
      {
        path: "/incident",
        name: "incident",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "liste",
            name: "incident-liste",
            component: () => import("@/views/app/incident/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "dashboard",
            name: "incident-dashboard",
            component: () => import("@/views/app/incident/Dashboard.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "jourj",
            name: "incident-jourj",
            component: () => import("@/views/app/incident/JourJ.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
        ]
      },
      {
        path: "/avis",
        name: "avis",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "liste",
            name: "avis-liste",
            component: () => import("@/views/app/avis/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          }
        ]
      },
      {
        path: "/utilisateur",
        name: "utilisateur",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[
          {
            path: "liste",
            name: "utilisateur-liste",
            component: () => import("@/views/app/utilisateur/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "liste/:service_id",
            name: "utilisateur-liste-service",
            component: () => import("@/views/app/utilisateur/Liste.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "test",
            name: "utilisateur-test",
            component: () => import("@/views/app/utilisateur/Test.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
          {
            path: "detail/:id",
            name: "utilisateur-detail",
            component: () => import("@/views/app/utilisateur/Detail.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },
        ]
      },
      {
        path: "/mon-compte",
        name: "mon-compte",
        component: () => import("@/views/app/MonCompte.vue"),
        meta: {
          rule: 'isAdmin',
              logged: true
        }
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta:{
          logged: true
        }
      },
    ]
  },
  {
    path: "/vl",
    component: () => import("@/views/layout/Main"),
    children: [
      {
        path: "forfait",
        name: "forfait",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "forfait-liste",
            component: () => import("@/views/vl/forfait/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },             
          {
            path: "detail/:id",
            name: "forfait-detail",
            component: () => import("@/views/vl/forfait/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
          {
            path: "nouveau",
            name: "forfait-nouveau",
            component: () => import("@/views/vl/forfait/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
          {
            path: "map",
            name: "forfait-map",
            component: () => import("@/views/vl/forfait/Map.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
        ]
      },
      {
        path: "lead",
        name: "lead",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "lead-liste",
            component: () => import("@/views/vl/lead/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },        
        ]
      },
      {
        path: "prospect",
        name: "vl-prospect",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "vl-prospect-liste",
            component: () => import("@/views/vl/prospect/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },             
          {
            path: "detail/:id",
            name: "vl-prospect-detail",
            component: () => import("@/views/vl/prospect/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
          {
            path: "nouveau",
            name: "vl-prospect-nouveau",
            component: () => import("@/views/vl/prospect/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },           
        ]
      }, 
      {
        path: "facture",
        name: "vl-facture",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "vl-facture-liste",
            component: () => import("@/views/vl/facture/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },                                 
        ]
      }, 
    ]
  },           
  {
    path: "/crm",
    component: () => import("@/views/layout/Main"),
    children: [
      {
        path: "lead",
        name: "crm-lead",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "crm-lead-liste",
            component: () => import("@/views/crm/lead/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },             
          {
            path: "detail/:id",
            name: "crm-lead-detail",
            component: () => import("@/views/crm/lead/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
          {
            path: "nouveau",
            name: "crm-lead-nouveau",
            component: () => import("@/views/crm/lead/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },           
        ]
      },      
    ]
  },
  {
    path: "/mm-offre/:uuid",
    name: "mm-offre-view",
    component: () => import("@/views/mm/offre/View.vue"),
    meta: {
      rule: 'isAdmin',                
      logged: false
    }
  },  
  {
    path: "/mm",
    name: "mm",
    component: () => import("@/views/layout/Main"),    
    children:[
      {
        path:'offre',
        name:'mm-offre',
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "mouvement",
            name: "mm-offre-mouvement",
            component: () => import("@/views/mm/offre/Mouvement.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },        
          {
            path: "detail/:id",
            name: "mm-offre-detail",
            component: () => import("@/views/mm/offre/Detail.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },     
          {
            path: "tous",
            name: "mm-offre-tous",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "mm-offre-tous-liste",
                component: () => import("@/views/mm/offre/tous/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "calendrier",
                name: "mm-offre-tous-calendar",
                component: () => import("@/views/mm/offre/tous/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "carte",
                name: "mm-offre-tous-map",
                component: () => import("@/views/mm/offre/tous/Map.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
            ]
          },
          {
            path: "disponible",
            name: "mm-offre-disponible",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "mm-offre-disponible-liste",
                component: () => import("@/views/mm/offre/disponible/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "calendrier",
                name: "mm-offre-disponible-calendar",
                component: () => import("@/views/mm/offre/disponible/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "carte",
                name: "mm-offre-disponible-map",
                component: () => import("@/views/mm/offre/disponible/Map.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
            ]
          },
          {
            path: "postulee",
            name: "mm-offre-postulee",
            component: () => import("@/views/layout/VueBootstrap.vue"),
            children:[
              {
                path: "liste",
                name: "mm-offre-postulee-liste",
                component: () => import("@/views/mm/offre/postulee/Liste.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "calendrier",
                name: "mm-offre-postulee-calendar",
                component: () => import("@/views/mm/offre/postulee/Calendar.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
              {
                path: "carte",
                name: "mm-offre-postulee-map",
                component: () => import("@/views/mm/offre/postulee/Map.vue"),
                meta: {
                  rule: 'isAdmin',
                  logged: true
                }
              },
            ]
          },
        ]
      },
      {
        path: "lead",
        name: "mm-lead",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[    
          {
            path: "liste",
            name: "mm-lead-liste",
            component: () => import("@/views/mm/lead/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },             
          {
            path: "detail/:id",
            name: "mm-lead-detail",
            component: () => import("@/views/mm/lead/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          }, 
          {
            path: "nouveau",
            name: "mm-lead-nouveau",
            component: () => import("@/views/mm/lead/Detail.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },           
        ]
      },  
      {
        path: "reglement",
        name: "reglement",
        component: () => import("@/views/layout/VueBootstrap.vue"),
        children:[            
          {
            path: "liste",
            name: "reglement-liste",
            component: () => import("@/views/mm/reglement/Liste.vue"),
            meta: {
              rule: 'isAdmin',                
              logged: true
            }
          },                      
          {
            path: "solde",
            name: "reglement-solde",
            component: () => import("@/views/mm/reglement/Solde.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },            
          {
            path: "soldes",
            name: "reglement-soldes",
            component: () => import("@/views/mm/reglement/Soldes.vue"),
            meta: {
              rule: 'isAdmin',
              logged: true
            }
          },            
        ]
      },
      {
        path: "facture",
        name: "mm-facture",
        component: () => import("@/views/mm/Facture.vue"),
        meta: {
          rule: 'isAdmin',                
          logged: true
        }
      }, 
    ]
  },   
  {
    path: "/document/:uuid",
    name: "document-detail",
    component: () => import("@/views/app/document/Detail2.vue"),
    meta: {
      logged: false
    }
  },
  {
    path: "/document/s/:uuid",
    name: "document-sign",
    component: () => import("@/views/app/document/Sign.vue"),
    meta: {
      logged: false
    }
  },  
  {
    path: "/chantier/:uuid", 
    name:'chantier-uuid',
    component: () => import("@/views/app/chantier/Uuid.vue"),
    meta: {
      logged: false
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

async function backVerify(from = false){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };    
  var url = Api.urlAPI + '/authorization/verify';
  if(from != false){
    url += "?url="+from
  }
  var response = await fetch(url,option);
  var res = await response.json();  
  return res;
}

async function init(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.urlAPI + '/magic/init'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}

router.beforeEach( async (to, from, next) => {  
  if(to.meta.logged === true){    
    if(Api.params === false && Api.getToken() != false){
      await init().then(res => {
        if(res.status){
          if(res.data.params.GEDType != undefined){
            res.data.params.GEDType.forEach((item) => {
              var label =  Api.getText(item.titre);
              if(item.required === true){
                label += "*"
              }
              item.label = label
            });
          }          
          var exists = ['UserStatus', 'GEDType', 'AvisStatus', 'DemenagementStatus', 'DemenagementPlanning', 'DemenagementType', 'FactureMBType'];
          for(const e of exists){
            if(res.data.params[e] != undefined){
              res.data.params[e].forEach((item) => {
                item.label = Api.getText(item.titre)
              });
            }
          }               
          Api.params = res.data.params;
          Api.schema = res.data.schema;          
          Api.menu = res.data.menu;
        }
      });
    }
    if(Api.getToken() != false){
      await backVerify(to.path).then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{                    
          next({path:'/login'});          
        }
      }).catch( () => {        
        next({path:'/login'})
      });
    }else{      
      next({path:'/login', query:{redirect: to.path}})
    }
  }else{
    next();
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
})
export default router
